import React, { useState, useEffect, useRef } from "react";
import userPlaceholder from "../../assets/images/placeholder.jpg";
import cookie, { set } from "js-cookie";
import Loader from "../../components/shared/Loader";
import { Container, Col, Row } from "reactstrap";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { updateMeta } from "../../store/actions/metaAction";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

const WorksCreate = ({ match }) => {
	const {
		params: { workID },
	} = match;
	const [loading, setLoading] = useState(true);
	const history = useHistory();
	const [formData, setFormData] = useState({
		image: {
			path: userPlaceholder,
			blob: true,
		},
	});
	const [Types, setTypes] = useState([]);
	const dispatch = useDispatch();

	const onStart = () => {
		dispatch(
			updateMeta({
				title: "اضافة منتج جديد",
				breadcrumb: "Product",
				button: {
					show: false,
					text: null,
					link: null,
				},
			})
		);
		axios
			.get(`${process.env.REACT_APP_API_URL}pages/work-type`, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
				},
			})
			.then(response => {
				setTypes(response.data.data.page.sections);
				axios
					.get(
						`${process.env.REACT_APP_API_URL}pages/our-works/sections/${workID}`,
						{
							headers: {
								Authorization: `Bearer ${cookie.get("token")}`,
								Accept: "application/json",
							},
						}
					)
					.then(response => setFormData({ ...response.data.data.section }));
				setLoading(false);
			});
	};

	useEffect(() => {
		onStart();
	}, []);

	const handlePicture = e => {
		const fileInput = document.querySelector(`#${e.target.id}`);
		setFormData({
			...formData,
			image: { path: URL.createObjectURL(fileInput.files[0]), blob: true },
		});
	};

	const handleSubmit = e => {
		e.preventDefault();
		// return
		const picture = document.querySelector("#picture");
		if (picture.files[0]) {
			setLoading(true);
			const pictureFormData = new FormData();
			pictureFormData.append("file", picture.files[0]);
			axios
				.post(`${process.env.REACT_APP_API_URL}/upload`, pictureFormData, {
					headers: {
						Authorization: `Bearer ${cookie.get("token")}`,
						Accept: "application/json",
						"Content-Type": "multipart/form-data",
					},
				})
				.then(response => {
					// console.log({
					// 	section: {
					// 		...formData,
					// 		title: formData?.titleObj?.ar ?? "new Work",
					// 		image: {
					// 			blob: false,
					// 			path: response.data.data.file.path,
					// 		},
					// 	},
					// });
					// return;
					axios
						.put(
							`${process.env.REACT_APP_API_URL}/pages/our-works/sections/${workID}`,
							{
								section: {
									...formData,
									title: formData?.titleObj?.ar ?? "new Work",
									image: {
										blob: false,
										path:
											process.env.REACT_APP_MAIN_URL +
											"/" +
											response.data.data.file.path,
									},
								},
							},
							{
								headers: {
									Authorization: `Bearer ${cookie.get("token")}`,
									Accept: "application/json",
								},
							}
						)
						.then(response => {
							history.push("/works/index");
							toast.success("تم اضافة الخدمة بنجاح!");
							setLoading(false);
						})
						.catch(function (error) {
							toast.error("خطأ، برجاء المحاولة مره اخرى!");
							setLoading(false);
						});
				});
		} else {
			axios
				.put(
					`${process.env.REACT_APP_API_URL}/pages/our-works/sections/${workID}`,
					{
						section: {
							...formData,
							title: formData?.titleObj?.ar ?? "new Work",
						},
					},
					{
						headers: {
							Authorization: `Bearer ${cookie.get("token")}`,
							Accept: "application/json",
						},
					}
				)
				.then(response => {
					history.push("/works/index");
					toast.success("تم اضافة الخدمة بنجاح!");
					setLoading(false);
				})
				.catch(function (error) {
					toast.error("خطأ، برجاء المحاولة مره اخرى!");
					setLoading(false);
				});
		}
	};

	return (
		<section>
			{loading && <Loader />}
			<form onSubmit={handleSubmit}>
				<div className="row">
					<Col xs={12} md={9}>
						<div className="card">
							<div className="card-body">
								<div className="row">
									<div className="col-md-12">
										<div className="form-item">
											<label htmlFor="title">العنوان</label>
											<input
												type="text"
												id="title"
												value={formData?.titleObj?.ar ?? ""}
												onChange={e =>
													setFormData({
														...formData,
														titleObj: {
															...formData?.titleObj,
															ar: e.target.value,
														},
													})
												}
											/>
										</div>
										<div className="form-item">
											<label htmlFor="titleEn">العنوان بالانجليزية</label>
											<input
												type="text"
												id="titleEn"
												value={formData?.titleObj?.en ?? ""}
												onChange={e =>
													setFormData({
														...formData,
														titleObj: {
															...formData?.titleObj,
															en: e.target.value,
														},
													})
												}
											/>
										</div>
										<div className="form-item">
											<label htmlFor="type">النوع</label>
											<select
												name=""
												id="type"
												value={formData?.type ?? "0"}
												onChange={e =>
													setFormData({
														...formData,
														type: e.target.value,
													})
												}
											>
												<option value="0">اختر النوع</option>
												{Types.map(type => (
													<option value={type.slug} key={type.slug}>
														{type.titleObj.ar}
													</option>
												))}
											</select>
										</div>
									</div>
								</div>
							</div>
						</div>
					</Col>
					<Col xs={12} md={3}>
						<div className="card">
							<div className="card-body">
								<div className="form-item">
									<label htmlFor="picture">صورة المنتج</label>
									<input type="file" onChange={handlePicture} id="picture" />
									<img
										src={
											formData?.image?.blob
												? formData.image.path
												: formData.image.path
										}
										alt="s"
										className="imagePreview"
									/>
								</div>
							</div>
						</div>
						<div className="form-item mt-3">
							<button type="submit" className="bt bt-success btn-block">
								ارسال
							</button>
						</div>
					</Col>
				</div>
			</form>
		</section>
	);
};

export default WorksCreate;
