import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import {
	Button,
	Col,
	Container,
	Modal,
	ModalBody,
	ModalFooter,
	Row,
} from "reactstrap";
import { v4 } from "uuid";

// needed assets
import userPlaceholder from "../../../../assets/images/placeholder.jpg";
const AboutusShow = ({ ID, loadersetter }) => {
	const [formData, setFormData] = useState();
	const [currentFeature, setCurrentFeature] = useState({});
	const [dataLoaded, setDataLoaded] = useState(false);

	const history = useHistory();
	let editor = null;

	useEffect(() => {
		axios
			.get(`${process.env.REACT_APP_API_URL}/pages/site-infos/sections/${ID}`, {
				headers: {
					Authorization: `Bearer ${Cookies.get("token")}`,
					Accept: "application/json",
				},
			})
			.then(function (response) {
				toast.info("جارء ادخال البيانات");
				setTimeout(() => {
					setFormData({ ...response.data.data.section });
					setDataLoaded(true);
					toast.success("يمكنك التعديل الان 😊") && loadersetter(false);
				}, 1000);
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة لاحقا!");
			});
	}, []);

	const addNewFeature = e => {
		e.preventDefault();
		if (!currentFeature.description || !currentFeature.title) {
			toast.warn("الرجاء اذخل الميزة بشكل صحيح");
			return;
		}
		dataLoaded &&
			setFormData({
				...formData,
				features: [...formData.features, { ...currentFeature, id: v4() }],
			});
		setCurrentFeature({});
	};

	const uploadImage = e => {
		try {
			loadersetter(true);
			const data = new FormData();
			data.append("file", e.target.files[0]);
			axios
				.post(`${process.env.REACT_APP_API_URL}upload`, data, {
					headers: {
						Authorization: `Bearer ${Cookies.get("token")}`,
						Accept: "application/json",
						"Content-Type": "multipart/form-data",
					},
				})
				.then(response => {
					setFormData({
						...formData,
						image:
							process.env.REACT_APP_MAIN_URL + response.data.data.file.path,
					});
					loadersetter(false);
				});
		} catch (error) {
			loadersetter(false);
		}
	};

	const submite = () => {
		axios
			.put(
				`${process.env.REACT_APP_API_URL}pages/site-infos/sections/${ID}`,
				{
					section: { ...formData, slug: ID },
				},
				{
					headers: {
						Authorization: `Bearer ${Cookies.get("token")}`,
						Accept: "application/json",
					},
				}
			)
			.then(res => {
				history.push("/siteinfo/index");
				toast.success("تم التحديث بنجاح!");
			});
	};
	const [modal, setModal] = useState(false);
	const toggle = () => setModal(!modal);

	return (
		<div>
			<Container>
				<Row>
					<div className="col-md-8">
						<div className="card">
							<div className="card-body">
								<div className="form-item">
									<label htmlFor="title">سنوات الخبرة</label>
									<input
										type="text"
										id="title"
										value={formData?.yearofexp ?? ""}
										onChange={e =>
											dataLoaded &&
											setFormData({ ...formData, yearofexp: e.target.value })
										}
									/>
								</div>
								<div className="form-item">
									<label htmlFor="description">الوصف</label>
									<CKEditor
										data={formData?.description?.ar ?? ""}
										onReady={editor => {
											editor.ui
												.getEditableElement()
												.parentElement.insertBefore(
													editor.ui.view.toolbar.element,
													editor.ui.getEditableElement()
												);
											editor = editor;
										}}
										onError={({ willEditorRestart }) => {
											if (willEditorRestart) {
												editor.ui.view.toolbar.element.remove();
											}
										}}
										onChange={(event, editor) => {
											const data = editor.getData();
											dataLoaded &&
												setFormData({
													...formData,
													description: {
														...formData?.description,
														ar: data,
													},
												});
										}}
										editor={DecoupledEditor}
										config={{
											contentsLangDirection: "rtl",
											language: "ar",
										}}
									/>
								</div>
								<div className="form-item">
									<label htmlFor="description">الوصف بالانجليزية</label>
									<CKEditor
										data={formData?.description?.en ?? ""}
										onReady={editor => {
											editor.ui
												.getEditableElement()
												.parentElement.insertBefore(
													editor.ui.view.toolbar.element,
													editor.ui.getEditableElement()
												);
											editor = editor;
										}}
										onError={({ willEditorRestart }) => {
											if (willEditorRestart) {
												editor.ui.view.toolbar.element.remove();
											}
										}}
										onChange={(event, editor) => {
											const data = editor.getData();
											dataLoaded &&
												setFormData({
													...formData,
													description: {
														...formData?.description,
														en: data,
													},
												});
										}}
										editor={DecoupledEditor}
										config={{
											contentsLangDirection: "ltr",
											language: "en",
										}}
									/>
								</div>
								<div className="card">
									<div className="card-body">
										<Container>
											<Row>
												<Col xs={12}>
													<div className="form-item">
														<label htmlFor="title">عنوان الميزه</label>
														<input
															type="text"
															id="title"
															onChange={e =>
																dataLoaded &&
																setCurrentFeature({
																	...currentFeature,
																	title: {
																		...currentFeature?.title,
																		ar: e.target.value,
																	},
																})
															}
														/>
													</div>
												</Col>
											</Row>
											<Row>
												<Col xs={12}>
													<div className="form-item">
														<label htmlFor="title">
															عنوان الميزه بالانجليزية
														</label>
														<input
															type="text"
															id="titleEn"
															onChange={e =>
																dataLoaded &&
																setCurrentFeature({
																	...currentFeature,
																	title: {
																		...currentFeature?.title,
																		en: e.target.value,
																	},
																})
															}
														/>
													</div>
												</Col>
											</Row>
											<Row>
												<Col>
													<div className="form-item">
														<label htmlFor="description">الوصف الميزة</label>
														<input
															type="text"
															id="descriptionAr"
															onChange={e =>
																dataLoaded &&
																setCurrentFeature({
																	...currentFeature,
																	description: {
																		...currentFeature?.description,
																		ar: e.target.value,
																	},
																})
															}
														/>
													</div>
												</Col>
											</Row>
											<Row>
												<Col>
													<div className="form-item">
														<label htmlFor="description">
															الوصف الميزة بالانجليزية
														</label>
														<input
															type="text"
															id="descriptionEn"
															onChange={e =>
																dataLoaded &&
																setCurrentFeature({
																	...currentFeature,
																	description: {
																		...currentFeature?.description,
																		en: e.target.value,
																	},
																})
															}
														/>
													</div>
												</Col>
											</Row>
											<Row>
												<Col>
													<div className="form-item mt-3">
														<button
															className="bt bt-success btn-block"
															onClick={addNewFeature}
														>
															اضافة ميزه جديده
														</button>
													</div>
												</Col>
											</Row>
											<Row>
												{formData?.features?.map((feature, index) => (
													<Col key={feature.id + `feature`}>
														<div
															style={{
																display: "flex",
																justifyContent: "flex-start",
																alignItems: "center",
																maxWidth: "99%",
															}}
														>
															<span
																style={{
																	width: "69px",
																	height: "64px",
																	minWidth: "69px",
																	background: "#00c6bb",
																	border: "2px solid #ffffff",
																	boxSizing: "border-box",
																	boxShadow:
																		"0px 4px 4px rgb(216 216 216 / 25%)",
																	display: "flex",
																	alignItems: "center",
																	justifyContent: "center",
																	marginLeft: "14px",
																	color: "#fff",
																}}
															>
																<svg
																	width="16"
																	height="21"
																	viewBox="0 0 16 21"
																	fill="none"
																	xmlns="http://www.w3.org/2000/svg"
																>
																	<path
																		d="M14.538 0.172426L13.0382 1.92224L10.9134 0.172426C10.8055 0.090689 10.6738 0.0464536 10.5384 0.0464536C10.4031 0.0464536 10.2714 0.090689 10.1635 0.172426L8.0387 1.92224L5.91392 0.172426C5.80601 0.090689 5.67434 0.0464536 5.53896 0.0464536C5.40359 0.0464536 5.27192 0.090689 5.164 0.172426L3.03923 1.92224L1.53938 0.172426C1.12537 -0.136136 0.53949 0.156802 0.53949 0.672373V19.4204C0.53949 19.936 1.12537 20.2289 1.53938 19.9203L3.03923 18.1705L5.164 19.9203C5.27192 20.0021 5.40359 20.0463 5.53896 20.0463C5.67434 20.0463 5.80601 20.0021 5.91392 19.9203L8.0387 18.1705L10.1635 19.9203C10.2714 20.0021 10.4031 20.0463 10.5384 20.0463C10.6738 20.0463 10.8055 20.0021 10.9134 19.9203L13.0382 18.1705L14.538 19.9203C14.9481 20.2289 15.5379 19.936 15.5379 19.4204V0.672373C15.5379 0.156802 14.952 -0.136136 14.538 0.172426ZM13.0382 14.1085C13.0382 14.2803 12.8976 14.4209 12.7257 14.4209H3.35169C3.17984 14.4209 3.03923 14.2803 3.03923 14.1085V13.4835C3.03923 13.3117 3.17984 13.1711 3.35169 13.1711H12.7257C12.8976 13.1711 13.0382 13.3117 13.0382 13.4835V14.1085ZM13.0382 10.3589C13.0382 10.5307 12.8976 10.6713 12.7257 10.6713H3.35169C3.17984 10.6713 3.03923 10.5307 3.03923 10.3589V9.73392C3.03923 9.56206 3.17984 9.42145 3.35169 9.42145H12.7257C12.8976 9.42145 13.0382 9.56206 13.0382 9.73392V10.3589ZM13.0382 6.60925C13.0382 6.78111 12.8976 6.92172 12.7257 6.92172H3.35169C3.17984 6.92172 3.03923 6.78111 3.03923 6.60925V5.98431C3.03923 5.81246 3.17984 5.67185 3.35169 5.67185H12.7257C12.8976 5.67185 13.0382 5.81246 13.0382 5.98431V6.60925Z"
																		fill="white"
																	/>
																</svg>
															</span>
															<div
																className=""
																style={{ padding: "1rem", maxWidth: "80%" }}
															>
																<h1
																	style={{
																		fontSize: "18px",
																		marginBottom: "9px",
																		fontWeight: "bold",
																	}}
																>
																	{feature?.title?.ar ?? ""}
																</h1>
																<p
																	style={{
																		fontSize: "14px",
																		color: "#303c45",
																		margin: "0",
																		overflow: "hidden",
																		whiteSpace: "nowrap",
																		maxWidth: "99%",
																		textOverflow: "ellipsis",
																	}}
																>
																	{feature?.description?.ar ?? ""}
																</p>
															</div>
															<i
																onClick={() => {
																	setCurrentFeature({ ...feature, index });
																	toggle();
																}}
																style={{
																	transition: "all ease 300ms",
																	cursor: "pointer",
																	color: "#24c38a",
																}}
																className="fas fa-edit ms-2"
															></i>
															<i
																onClick={e => {
																	e.target.style.transform = "scale(1.3)";
																	e.target.style.color = "red";
																	setTimeout(
																		() =>
																			toast.info("تم مسح الميزة 👌") &&
																			setFormData({
																				...formData,
																				features: [
																					...formData?.features?.filter(
																						filterdFeature =>
																							filterdFeature.id !== feature.id
																					),
																				],
																			}),
																		350
																	);
																}}
																style={{ transition: "all ease 300ms" }}
																className="fas fa-trash-alt"
															></i>
														</div>
													</Col>
												))}
											</Row>
										</Container>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-4">
						<div className="card">
							<div className="card-body">
								<div className="form-item">
									<input type="file" onChange={uploadImage} id="picture" />
									<img
										src={formData?.image ? formData.image : userPlaceholder}
										alt="avatar"
										className="imagePreview"
									/>
								</div>
							</div>
						</div>
						<div className="form-item mt-3">
							<button className="bt bt-success btn-block" onClick={submite}>
								ارسال
							</button>
						</div>
					</div>
					<Modal isOpen={modal} toggle={toggle}>
						<ModalBody>
							<Row>
								<Col xs={12}>
									<div className="form-item">
										<label htmlFor="title">عنوان الميزة</label>
										<input
											type="text"
											id="title"
											onChange={e => {
												dataLoaded &&
													setCurrentFeature({
														...currentFeature,
														title: {
															...currentFeature?.title,
															ar: e.target.value,
														},
													});
											}}
											defaultValue={currentFeature?.title?.ar}
										/>
									</div>
								</Col>
							</Row>
							<Row>
								<Col xs={12}>
									<div className="form-item">
										<label htmlFor="title">عنوان الميزة بالانجليزية</label>
										<input
											type="text"
											id="titleEn"
											onChange={e =>
												dataLoaded &&
												setCurrentFeature({
													...currentFeature,
													title: {
														...currentFeature?.title,
														en: e.target.value,
													},
												})
											}
											defaultValue={currentFeature?.title?.en}
										/>
									</div>
								</Col>
							</Row>
							<Row>
								<Col>
									<div className="form-item">
										<label htmlFor="description">الوصف الميزة</label>
										<input
											type="text"
											id="descriptionAr"
											onChange={e =>
												dataLoaded &&
												setCurrentFeature({
													...currentFeature,
													description: {
														...currentFeature?.description,
														ar: e.target.value,
													},
												})
											}
											defaultValue={currentFeature?.description?.ar}
										/>
									</div>
								</Col>
							</Row>
							<Row>
								<Col>
									<div className="form-item">
										<label htmlFor="description">
											الوصف الميزة بالانجليزية
										</label>
										<input
											type="text"
											id="descriptionEn"
											onChange={e =>
												dataLoaded &&
												setCurrentFeature({
													...currentFeature,
													description: {
														...currentFeature?.description,
														en: e.target.value,
													},
												})
											}
											defaultValue={currentFeature?.description?.en}
										/>
									</div>
								</Col>
							</Row>
						</ModalBody>
						<ModalFooter>
							<Button
								color="success"
								onClick={() => {
									if (currentFeature?.index >= 0) {
										let formTmp = formData;
										formTmp.features[currentFeature.index] = currentFeature;
										setFormData(formTmp);
									}
									setModal(!modal);
								}}
							>
								حفظ
							</Button>
						</ModalFooter>
					</Modal>
				</Row>
			</Container>
		</div>
	);
};

export default AboutusShow;
