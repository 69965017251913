import React, { useEffect, useState } from "react";
import Loader from "../../../components/shared/Loader";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { updateMeta } from "../../../store/actions/metaAction";
import userPlaceholder from "../../../assets/images/placeholder.jpg";
import cookie from "js-cookie";
import server, { uploadServer } from "../../../api/server";
import axios from "axios";
import { Select } from "../../../components/shared/FormComponents";

const SocialTypesShow = ({ match }) => {
	const auth = useSelector(state => state.auth.user);
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const [socialTypes, setSocialTypes] = useState([]);
	const [selected, setSelected] = useState("");
	const {
		params: { linkId },
	} = match;

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		getValues,
		reset,
	} = useForm();
	const [pictureImg, setPictureImg] = useState();

	const onStart = async () => {
		try {
			const initialData = await Promise.all([
				server(cookie).get(`/settings/socialTypes/${linkId}`),
			]);
			let data = initialData?.[0]?.data?.data?.socialType;

			setSelected(data?.socialType);
			setValue("nameAr", data?.name?.ar);
			setValue("nameEn", data?.name?.en);
			setValue("pictureImg", data?.icon);
			setPictureImg(data?.icon);

			dispatch(
				updateMeta({
					title: `${data?.name?.ar ?? " "}`,
					breadcrumb: "تعديل نوع رابط",
					button: {
						show: false,
						text: null,
						link: null,
					},
				})
			);
		} catch (error) {
			console.log(error);
		}
	};

	// handleMethods
	const submitHandler = async data => {
		setLoading(true);
		try {
			const dataForm = new FormData();
			dataForm.append("file", data.pictureUrl[0]);
			let imageResponse = "";
			if (getValues()?.picturePath) {
				imageResponse = await uploadServer(cookie).post("/upload", dataForm);
			} else {
				imageResponse = getValues()?.pictureImg;
			}
			const finalData = {
				name: {
					ar: data?.nameAr,
					en: data?.nameEn,
				},
				icon:
					process.env.REACT_APP_MAIN_URL +
						imageResponse?.data?.data?.file?.path || imageResponse,
			};
			const dataResponse = await server(cookie).put(
				`/settings/socialTypes/${linkId}`,
				finalData
			);
			reset();
			onStart();
			setPictureImg(userPlaceholder);
			history.push("/social/types");
		} catch (error) {
			console.log(error);
		}
		setLoading(false);
	};

	const handlePicture = () => {
		const tempValue = getValues("pictureUrl");
		if (tempValue.length < 1) return;
		const blobUrl = URL?.createObjectURL(tempValue?.[0]);
		setValue("picturePath", blobUrl);
		setPictureImg(blobUrl);
	};
	// watchers
	useEffect(() => {
		onStart();
	}, []);

	return (
		<section>
			{loading && <Loader />}
			<form onSubmit={handleSubmit(submitHandler)}>
				<div className="row">
					<div className="col-md-12">
						<div className="card">
							<div className="card-body">
								<div className="form-item">
									<label htmlFor="nameAr">اسم نوع باللغة العربية</label>
									<input
										type="text"
										name="name"
										id="nameAr"
										{...register("nameAr", {
											required: true,
										})}
									/>
								</div>
							</div>
							<div className="card-body">
								<div className="form-item">
									<label htmlFor="nameEn">اسم نوع بالغة الانجليزية</label>
									<input
										type="text"
										name="name"
										id="nameEn"
										{...register("nameEn", {
											required: true,
										})}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-12">
						<div className="card">
							<div className="card-body">
								<div className="form-item">
									<label htmlFor="picture">البانر</label>
									<input
										type="file"
										id="picture"
										{...register("pictureUrl", {
											onChange: () => handlePicture(),
										})}
									/>
									<img
										src={`${pictureImg}`}
										alt="avatar"
										className="imagePreview"
									/>
								</div>
							</div>
						</div>
						<input
							type="text"
							name=""
							id=""
							style={{ display: "none" }}
							{...register("picturePath")}
						/>
						<div className="form-item mt-3">
							<button className="bt bt-success btn-block">ارسال</button>
						</div>
					</div>
				</div>
			</form>
		</section>
	);
};

export default SocialTypesShow;
