import aboutUs from "./AboutusShow";
import NavBar from "./NavBarShow";
import Footer from "./FooterShow";
import heroSection from "./HeroSectionShow";
import story from "./StoryShow";
import ServicesSection from "./ServicesSection";
import breadcrumbs from "./Breadcrumbs";
import address from "./Address";
import certificate from "./Certificate.jsx";

export default {
	aboutUs,
	ServicesSection,
	heroSection,
	story,
	Footer,
	NavBar,
	breadcrumbs,
	"contact-us": breadcrumbs,
	certificate,
	// address,
};
