import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "axios";
import cookie from "js-cookie";
import { toast } from "react-toastify";
import userPlaceholder from "../../assets/images/placeholder.jpg";
import { updateMeta } from "../../store/actions/metaAction";
import { Col, Row, Container } from "reactstrap";
import Loader from "../../components/shared/Loader";

const CommentsShow = ({ match }) => {
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const [comment, setComment] = useState({});
	const [picture, setPicture] = useState({
		blob: true,
		path: userPlaceholder,
		placeholder: true,
	});
	const {
		params: { centerID },
	} = match;
	const dispatch = useDispatch();

	const onStart = () => {
		dispatch(
			updateMeta({
				title: "اضافة الانجازات جديد",
				breadcrumb: "الانجازات",
				button: {
					show: false,
					text: null,
					link: null,
				},
			})
		);
		axios
			.get(
				`${process.env.REACT_APP_API_URL}pages/comments/sections/${centerID}`,
				{
					headers: {
						Authorization: `Bearer ${cookie.get("token")}`,
						Accept: "application/json",
					},
				}
			)
			.then(response => {
				setComment({ ...response.data.data.section });
				setPicture({ ...response.data.data.section.image });
			});
	};

	useEffect(() => {
		onStart();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handlePicture = e => {
		const fileInput = document.querySelector(`#${e.target.id}`);
		try {
			setPicture({
				path: URL.createObjectURL(fileInput.files[0]),
				blob: true,
				placeholder: false,
			});
		} catch (error) {}
	};

	const handleSubmit = e => {
		e.preventDefault();
		const pictureElement = document.querySelector("#picture");

		if (pictureElement.files[0]) {
			setLoading(true);
			const Miandata = new FormData();
			Miandata.append("file", pictureElement.files[0]);
			axios
				.post(`${process.env.REACT_APP_API_URL}/upload`, Miandata, {
					headers: {
						Authorization: `Bearer ${cookie.get("token")}`,
						Accept: "application/json",
						"Content-Type": "multipart/form-data",
					},
				})
				.then(
					response =>
						toast.info(
							"تم الانتهاء من رفع الصورة و جاري رفع البيانات الاخره"
						) &&
						axios
							.put(
								`${process.env.REACT_APP_API_URL}pages/comments/sections/${centerID}`,
								{
									section: {
										...comment,
										image: {
											path:
												process.env.REACT_APP_MAIN_URL +
												response.data.data.file.path,
											blob: false,
											placeholder: false,
										},
									},
								},
								{
									headers: {
										Authorization: `Bearer ${cookie.get("token")}`,
										Accept: "application/json",
									},
								}
							)
							.then(
								_ =>
									toast.success("تم تحديث التعليق بنجاح") &&
									history.push("/comments/index")
							)
							.catch(_ => toast.error("حدث خطا !"))
				)
				.catch(_ => toast.error("حدث خطا"));
		} else {
			axios
				.put(
					`${process.env.REACT_APP_API_URL}pages/comments/sections/${centerID}`,
					{
						section: {
							...comment,
						},
					},
					{
						headers: {
							Authorization: `Bearer ${cookie.get("token")}`,
							Accept: "application/json",
						},
					}
				)
				.then(
					_ =>
						toast.success("تم تحديث التعليق بنجاح") &&
						history.push("/comments/index")
				)
				.catch(_ => toast.error("حدث خطا !"));
		}
	};

	if (loading) return <Loader />;

	return (
		<section>
			<Container>
				<div className="card">
					<div className="card-body">
						<Row style={{ marginTop: "2rem" }}>
							<Col
								style={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "flex-start",
									alignItems: "flex-start",
								}}
							>
								<div className="form-item">
									<label htmlFor="clientNameAR">اسم المستخدم</label>

									<input
										type="text"
										id="clientNameAR"
										name="clientNameAr"
										value={comment?.name?.ar ?? ""}
										onChange={e => {
											setComment({
												...comment,
												name: { ...comment.name, ar: e.target.value },
											});
										}}
									/>
								</div>
							</Col>

							<Col
								style={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "flex-start",
									alignItems: "flex-start",
								}}
							>
								<div className="form-item">
									<label htmlFor="clientNameEN">اسم المستخدم بالانجليزية</label>

									<input
										type="text"
										id="clientNameEN"
										name="clientNameEn"
										value={comment?.name?.en ?? ""}
										onChange={e => {
											setComment({
												...comment,
												name: { ...comment.name, en: e.target.value },
											});
										}}
									/>
								</div>
							</Col>
						</Row>
						<Row style={{ paddingTop: "1rem" }}>
							<Col
								style={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "flex-start",
									alignItems: "flex-start",
								}}
							>
								<div className="form-item">
									<label htmlFor="JobNameAR">الوظيفة</label>

									<input
										type="text"
										id="JobNameAR"
										value={comment?.position?.ar ?? ""}
										name="jobNameAR"
										onChange={e => {
											setComment({
												...comment,
												position: { ...comment.position, ar: e.target.value },
											});
										}}
									/>
								</div>
							</Col>

							<Col
								style={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "flex-start",
									alignItems: "flex-start",
								}}
							>
								<div className="form-item">
									<label htmlFor="JobNameEN"> الوظيفة بالانجليزية </label>

									<input
										type="text"
										id="JobNameEN"
										value={comment?.position?.en ?? ""}
										name="jobNameEN"
										onChange={e => {
											setComment({
												...comment,
												position: { ...comment.position, en: e.target.value },
											});
										}}
									/>
								</div>
							</Col>
						</Row>
						<Row>
							<Col>
								<div className="form-item">
									<label htmlFor="CommentAR">تفصيل التعليق </label>

									<textarea
										name=""
										id="CommentAR"
										rows="5"
										style={{ resize: "none", width: "100%" }}
										value={comment?.opinion?.ar ?? ""}
										onChange={e => {
											setComment({
												...comment,
												opinion: { ...comment.opinion, ar: e.target.value },
											});
										}}
									></textarea>
								</div>
							</Col>
						</Row>
						<Row>
							<Col>
								<div className="form-item">
									<label htmlFor="CommentEN">تفصيل التعليق بالانجليزية</label>

									<textarea
										name=""
										id="CommentEN"
										rows="5"
										value={comment?.opinion?.en ?? ""}
										style={{ resize: "none", width: "100%" }}
										onChange={e => {
											setComment({
												...comment,
												opinion: { ...comment.opinion, en: e.target.value },
											});
										}}
									></textarea>
								</div>
							</Col>
						</Row>

						<Row>
							<Col>
								<div className="form-item">
									<label htmlFor="picture">صورة العميل</label>
									<input type="file" onChange={handlePicture} id="picture" />
									<img
										src={picture.path}
										alt="avatar"
										className="imagePreview"
									/>
								</div>
							</Col>
						</Row>

						<Row>
							<Col>
								<div className="form-item mt-3">
									<button
										className="bt bt-success btn-block"
										onClick={handleSubmit}
									>
										ارسال
									</button>
								</div>
							</Col>
						</Row>
					</div>
				</div>
			</Container>
		</section>
	);
};

export default CommentsShow;
