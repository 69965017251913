import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import cookie from "js-cookie";
import { toast } from "react-toastify";
import Loader from "../../../components/shared/Loader";
import userPlaceholder from "../../../assets/images/placeholder.jpg";
import { updateMeta } from "../../../store/actions/metaAction";
import { useHistory } from "react-router-dom";

const WorksVideosShow = ({ match }) => {
	const {
		params: { worksVideoId },
	} = match;

	const auth = useSelector(state => state.auth.user);
	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");
	const [titleEn, setTitleEn] = useState("");
	const [descriptionEn, setDescriptionEn] = useState("");
	const [loading, setLoading] = useState(true);
	const dispatch = useDispatch();
	const [video, setVideo] = useState(userPlaceholder);
	const history = useHistory();

	useEffect(() => {
		onStart();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onStart = () => {
		dispatch(
			updateMeta({
				title: "تعديل تفاصيل الفيديو",
				breadcrumb: "الفيديو",
				button: {
					show: false,
					text: null,
					link: null,
				},
			})
		);
		axios
			.get(`${process.env.REACT_APP_API_URL}/posts/${worksVideoId}`, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
				},
			})
			.then(function (response) {
				setTitle(response.data.data.data.title.ar);
				setTitleEn(response.data.data.data.title.en);
				setDescription(response.data.data.data.description.ar);
				setDescriptionEn(response.data.data.data.description.en);

				setVideo(response.data.data.data.video);
				setLoading(false);
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة لاحقا!");
			});
	};

	const handleMedia = () => {
		setLoading(true);
		const fileInput = document.querySelector("#video");
		setVideo(URL.createObjectURL(fileInput.files[0]));
		const data = new FormData();
		data.append("file", fileInput.files[0]);
		axios
			.post(`${process.env.REACT_APP_MAIN_URL}/api/admincp/upload`, data, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
					"Content-Type": "multipart/form-data",
				},
			})
			.then(response => {
				history.push("/mainVideo/index");
				setVideo(process.env.REACT_APP_MAIN_URL + response.data.data.name);
				setLoading(false);
				toast.success("تم رفع الفيديو بنجاح!");
			})
			.catch(error => {
				toast.error("خطأ في رفع الفيديو برجاء المحاولة مرة أخرى!");
				setLoading(false);
			});
	};

	const handleSubmit = e => {
		e.preventDefault();
		if (!title || !titleEn || !description || !descriptionEn) {
			return toast.error("جميع البيانات مطلوبة");
		}

		setLoading(true);
		const data = {
			title: {
				ar: title,
				en: titleEn,
			},

			description: {
				ar: description,
				en: descriptionEn,
			},
			video,
		};
		axios
			.put(
				`${process.env.REACT_APP_API_URL}/posts/${worksVideoId}`,
				{
					admin_id: auth.id,
					category_id: 34,
					data: data,
				},
				{
					headers: {
						Authorization: `Bearer ${cookie.get("token")}`,
						Accept: "application/json",
					},
				}
			)
			.then(function (response) {
				history.push("/works/worksvideos/index");

				toast.success("تم تحديث الفديو بنجاح!");
				setLoading(false);
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة مره اخرى!");
				setLoading(false);
			});
	};

	return (
		<section>
			{loading && <Loader />}
			<form onSubmit={handleSubmit}>
				<div className="row">
					<div className="col-md-6">
						<div className="card">
							<div className="card-header">الرؤيه</div>
							<div className="card-body">
								<div className="form-item">
									<label htmlFor="title">العنوان</label>
									<input
										type="text"
										id="title"
										value={title}
										onChange={e => setTitle(e.target.value)}
									/>
								</div>

								<div className="form-item">
									<label htmlFor="description">الوصف</label>
									<textarea
										id="description"
										cols="30"
										rows="2"
										onChange={e => setDescription(e.target.value)}
										value={description}
									/>
								</div>

								<div className="form-item">
									<label htmlFor="title"> العنوان بالانجليزيه </label>
									<input
										type="text"
										id="title"
										onChange={e => setTitleEn(e.target.value)}
										value={titleEn}
									/>
								</div>

								<div className="form-item">
									<label htmlFor="description"> الوصف بالانجليزيه</label>
									<textarea
										id="description"
										cols="30"
										rows="2"
										onChange={e => setDescriptionEn(e.target.value)}
										value={descriptionEn}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-6">
						<div className="card">
							<div className="card-body">
								<div className="form-item">
									<input type="file" onChange={handleMedia} id="video" />
								</div>
							</div>
						</div>
						<div className="form-item mt-3">
							<button className="bt bt-success btn-block">ارسال</button>
						</div>
					</div>
				</div>
			</form>
		</section>
	);
};

export default WorksVideosShow;
