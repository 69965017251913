import React, { useState, useEffect } from "react";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import axios from "axios";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { Row, Container, Col } from "reactstrap";

// needed assets
import userPlaceholder from "../../../../assets/images/placeholder.jpg";
import { useHistory } from "react-router";

const Breadcrumbs = ({ ID, loadersetter }) => {
	const [formData, setFormData] = useState();
	const [dataLoaded, setDataLoaded] = useState(false);

	const history = useHistory();
	const uploadImage = e => {
		try {
			loadersetter(true);
			const data = new FormData();
			data.append("file", e.target.files[0]);
			axios
				.post(`${process.env.REACT_APP_API_URL}upload`, data, {
					headers: {
						Authorization: `Bearer ${Cookies.get("token")}`,
						Accept: "application/json",
						"Content-Type": "multipart/form-data",
					},
				})
				.then(response => {
					setFormData({
						...formData,
						image:
							process.env.REACT_APP_MAIN_URL + response.data.data.file.path,
					});
					loadersetter(false);
				});
		} catch (error) {

			loadersetter(false);
		}
	};

	const submite = () => {
		axios
			.put(
				`${process.env.REACT_APP_API_URL}pages/site-infos/sections/${ID}`,
				{
					section: { ...formData, slug: ID },
				},
				{
					headers: {
						Authorization: `Bearer ${Cookies.get("token")}`,
						Accept: "application/json",
					},
				}
			)
			.then(res => {
				history.push("/siteinfo/index");
				toast.success("تم التحديث بنجاح!");
			});
	};

	useEffect(() => {
		axios
			.get(`${process.env.REACT_APP_API_URL}/pages/site-infos/sections/${ID}`, {
				headers: {
					Authorization: `Bearer ${Cookies.get("token")}`,
					Accept: "application/json",
				},
			})
			.then(function (response) {
				toast.info("جارء ادخال البيانات");
				setTimeout(() => {
					setFormData({ ...response.data.data.section });
					setDataLoaded(true);
					toast.success("يمكنك التعديل الان 😊") && loadersetter(false);
				}, 1000);
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة لاحقا!");
			});
	}, []);

	let editor = null;

	return (
		<div>
			<Container>
				<Row>
					<div className="col-md-4">
						<div className="card">
							<div className="card-body">
								<div className="form-item">
									<input type="file" onChange={uploadImage} id="picture" />
									<img
										src={formData?.image ? formData.image : userPlaceholder}
										alt="avatar"
										className="imagePreview"
									/>
								</div>
							</div>
						</div>
						<div className="form-item mt-3">
							<button className="bt bt-success btn-block" onClick={submite}>
								ارسال
							</button>
						</div>
					</div>
				</Row>
			</Container>
		</div>
	);
};

export default Breadcrumbs;
