import React, { useEffect, useState } from "react";
import Loader from "../../../components/shared/Loader";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { updateMeta } from "../../../store/actions/metaAction";
import userPlaceholder from "../../../assets/images/placeholder.jpg";
import cookie from "js-cookie";
import server, { uploadServer } from "../../../api/server";
import axios from "axios";
import { Select } from "../../../components/shared/FormComponents";

const LinksShow = ({ match }) => {
	const auth = useSelector(state => state.auth.user);
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const [socialTypes, setSocialTypes] = useState([]);
	const [selected, setSelected] = useState("");
	const {
		params: { linkId },
	} = match;

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		reset,
	} = useForm();

	const onStart = async () => {
		try {
			const initialData = await Promise.all([
				server(cookie).get("/settings/socialTypes"),
				server(cookie).get(`/settings/socials/${linkId}`),
			]);
			setSocialTypes([
				...initialData[0].data.data.socialTypes
					.filter(
						type => !["phon", "e-mail", "location"].includes(type.name.en)
					)
					.map(type => ({
						...type,
						slug: type.id,
					})),
			]);

			setValue("socialType", initialData?.[1]?.data?.data?.socialType?.id);
			setSelected(initialData?.[1]?.data?.data?.socialType);
			setValue("name", initialData?.[1]?.data?.data?.name);
			setValue("link", initialData?.[1]?.data?.data?.link);

			dispatch(
				updateMeta({
					title: `${initialData?.[1]?.data?.data?.name?.ar ?? " "}`,
					breadcrumb: "الرابط",
					button: {
						show: false,
						text: null,
						link: null,
					},
				})
			);
		} catch (error) {
			console.log(error);
		}
	};

	// handleMethods
	const submitHandler = async data => {
		setLoading(true);
		try {
			const finalData = {
				socialType: data.socialType ?? selected.id,
				name: data.name,
				link: data.link,
			};
			const dataResponse = await server(cookie).put(
				`/settings/socials/${linkId}`,
				finalData
			);
			reset();
			onStart();
		} catch (error) {
			console.log(error);
		}
		setLoading(false);
	};

	// watchers
	useEffect(() => {
		onStart();
	}, []);

	return (
		<section>
			{loading && <Loader />}
			<form onSubmit={handleSubmit(submitHandler)}>
				<div className="row">
					<div className="col-md-12">
						<div className="card">
							{!["phon", "e-mail", "location"].includes(selected?.name?.en) && (
								<div className="card-body">
									<div className="form-item">
										<label htmlFor="socialType">نوع الرابط</label>
										<Select
											id="socialType"
											name="socialType"
											required={true}
											options={socialTypes}
											register={register}
										/>
									</div>
								</div>
							)}
							<div className="card-body">
								<div className="form-item">
									<label htmlFor="nameEn">رابط التواصل</label>
									<input
										type="text"
										name="link"
										id="link"
										{...register("link", {
											required: true,
										})}
									/>
								</div>
							</div>
						</div>
					</div>

					<div className="form-item mt-3">
						<button className="bt bt-success btn-block">ارسال</button>
					</div>
				</div>
				<input
					type="text"
					name="name"
					id=""
					style={{ display: "none" }}
					{...register("name")}
				/>
				<input
					type="text"
					name="socialType"
					id=""
					style={{ display: "none" }}
					{...register("socialType")}
				/>
			</form>
		</section>
	);
};

export default LinksShow;
