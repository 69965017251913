import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import cookie from "js-cookie";
import { toast } from "react-toastify";
import Loader from "../../../components/shared/Loader";
import userPlaceholder from "../../../assets/images/placeholder.jpg";
import { updateMeta } from "../../../store/actions/metaAction";
import { useHistory } from "react-router";

const InformationShow = ({ match }) => {
	const {
		params: { informationId },
	} = match;

	const auth = useSelector(state => state.auth.user);
	const [firstTitle, setFirstTitle] = useState("");
	const [secondTitle, setSecondTitle] = useState("");
	const [thirdTitle, setThirdTitle] = useState("");
	const [firstTitleEn, setFirstTitleEn] = useState("");
	const [secondTitleEn, setSecondTitleEn] = useState("");
	const [thirdTitleEn, setThirdTitleEn] = useState("");
	const [firstDescription, setFirstDescription] = useState("");
	const [secondDescription, setSecondDescription] = useState("");
	const [thirdDescription, setThirdDescription] = useState("");
	const [firstDescriptionEn, setFirstDescriptionEn] = useState("");
	const [secondDescriptionEn, setSecondDescriptionEn] = useState("");
	const [thirdDescriptionEn, setThirdDescriptionEn] = useState("");
	const [loading, setLoading] = useState(true);
	const [picture, setPicture] = useState(userPlaceholder);

	const dispatch = useDispatch();
	const history = useHistory();

	useEffect(() => {
		onStart();
	}, []);

	const onStart = () => {
		dispatch(
			updateMeta({
				title: "تعديل تفاصيل البيانات",
				breadcrumb: "البيانات",
				button: {
					show: false,
					text: null,
					link: null,
				},
			})
		);
		axios
			// .get(`${process.env.REACT_APP_API_URL}/posts/${informationId}`, {
			.get(`${process.env.REACT_APP_API_URL}/posts/about_us_info/all`, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
				},
			})
			.then(function (response) {
				// console.log(
				// 	response?.data?.data[0]?.categories[0]?.posts[0]?.data
				// 		.secondDescription,
				// 	"this is response"
				// );
				setFirstTitle(
					response.data.data[0].categories[0].posts[0].data.firstTitle.ar
				);
				setSecondTitle(
					response.data.data[0].categories[0].posts[0].data.secondTitle.ar
				);
				setThirdTitle(
					response.data.data[0].categories[0].posts[0].data.thirdTitle.ar
				);

				setFirstTitleEn(
					response.data.data[0].categories[0].posts[0].data.firstTitle.en
				);
				setSecondTitleEn(
					response.data.data[0].categories[0].posts[0].data.secondTitle.en
				);
				setThirdTitleEn(
					response.data.data[0].categories[0].posts[0].data.thirdTitle.en
				);

				setFirstDescription(
					response.data.data[0].categories[0].posts[0].data.firstDescription.ar
				);
				setSecondDescription(
					v =>
						(v =
							response.data.data[0].categories[0].posts[0].data
								.secondDescription?.ar)
				);
				setThirdDescription(
					response.data.data[0].categories[0].posts[0].data.thirdDescription.ar
				);

				setFirstDescriptionEn(
					v =>
						(v =
							response.data.data[0].categories[0].posts[0].data.firstDescription
								.en)
				);

				setSecondDescriptionEn(
					response.data.data[0].categories[0].posts[0].data.secondDescription.en
				);
				setThirdDescriptionEn(
					response.data.data[0].categories[0].posts[0].data.thirdDescription.en
				);
				setPicture(response.data.data[0].categories[0].posts[0].data.image);

				setLoading(false);
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة لاحقا!");
			});
	};

	const handlePicture = () => {
		setLoading(true);
		const fileInput = document.querySelector("#picture");
		setPicture(URL.createObjectURL(fileInput.files[0]));
		const data = new FormData();
		data.append("file", fileInput.files[0]);
		axios
			.post(`${process.env.REACT_APP_MAIN_URL}/api/admincp/upload`, data, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
					"Content-Type": "multipart/form-data",
				},
			})
			.then(response => {
				setPicture(process.env.REACT_APP_MAIN_URL + response.data.data.name);
				setLoading(false);
				toast.success("تم رفع الصورة بنجاح!");
			})
			.catch(error => {
				toast.error("خطأ في رفع الصورة، برجاء المحاولة مرة أخرى!");
				setLoading(false);
			});
	};

	const handleSubmit = e => {
		e.preventDefault();

		if (
			!firstTitle ||
			!firstDescription ||
			!firstTitleEn ||
			!firstDescriptionEn ||
			!secondTitle ||
			!secondDescription ||
			!secondTitleEn ||
			!secondDescriptionEn ||
			!thirdTitle ||
			!thirdDescription ||
			!thirdTitleEn ||
			!thirdDescriptionEn
		) {
			return toast.error("جميع البيانات مطلوبة");
		}

		setLoading(true);
		const data = {
			firstTitle: {
				ar: firstTitle,
				en: firstTitleEn,
			},

			firstDescription: {
				ar: firstDescription,
				en: firstDescriptionEn,
			},
			secondTitle: {
				ar: secondTitle,
				en: secondTitleEn,
			},

			secondDescription: {
				ar: secondDescription,
				en: secondDescriptionEn,
			},
			thirdTitle: {
				ar: thirdTitle,
				en: thirdTitleEn,
			},

			thirdDescription: {
				ar: thirdDescription,
				en: thirdDescriptionEn,
			},

			image: picture,
		};
		axios
			.put(
				`${process.env.REACT_APP_API_URL}/posts/275`,
				{
					admin_id: auth.id,
					category_id: 35,
					data: data,
				},
				{
					headers: {
						Authorization: `Bearer ${cookie.get("token")}`,
						Accept: "application/json",
					},
				}
			)
			.then(function (response) {
				history.push("/aboutPage/information/show");

				toast.success("تم تحديث المقدمه بنجاح!");

				setLoading(false);
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة مره اخرى!");
				setLoading(false);
			});
	};

	return (
		<section>
			{loading && <Loader />}
			<form onSubmit={handleSubmit}>
				<div className="row">
					<div className="col-md-9">
						<div className="card">
							<div className="card-header"> البيان الاول</div>
							<div className="card-body">
								<div className="form-item">
									<label htmlFor="title">العنوان</label>
									<input
										type="text"
										id="title"
										value={firstTitle}
										onChange={e => setFirstTitle(e.target.value)}
									/>
								</div>

								<div className="form-item">
									<label htmlFor="description">الوصف</label>
									<textarea
										id="description"
										cols="30"
										rows="2"
										onChange={e => setFirstDescription(e.target.value)}
										value={firstDescription}
									/>
								</div>

								<div className="form-item">
									<label htmlFor="title"> العنوان بالانجليزيه </label>
									<input
										type="text"
										id="title"
										onChange={e => setFirstTitleEn(e.target.value)}
										value={firstTitleEn}
									/>
								</div>

								<div className="form-item">
									<label htmlFor="description"> الوصف بالانجليزيه</label>
									<textarea
										id="description"
										cols="30"
										rows="2"
										onChange={e => setFirstDescriptionEn(e.target.value)}
										value={firstDescriptionEn}
									/>
								</div>
							</div>
						</div>
						{/* ********************************************************************* */}
						<div className="card">
							<div className="card-header"> البيان الثاني</div>
							<div className="card-body">
								<div className="form-item">
									<label htmlFor="title">العنوان</label>
									<input
										type="text"
										id="title"
										value={secondTitle}
										onChange={e => setSecondTitle(e.target.value)}
									/>
								</div>

								<div className="form-item">
									<label htmlFor="description">الوصف</label>
									<textarea
										id="description"
										cols="30"
										rows="2"
										onChange={e => setSecondDescription(e.target.value)}
										value={secondDescription}
									/>
								</div>

								<div className="form-item">
									<label htmlFor="title"> العنوان بالانجليزيه </label>
									<input
										type="text"
										id="title"
										onChange={e => setSecondTitleEn(e.target.value)}
										value={secondTitleEn}
									/>
								</div>

								<div className="form-item">
									<label htmlFor="description"> الوصف بالانجليزيه</label>
									<textarea
										id="description"
										cols="30"
										rows="2"
										onChange={e => setSecondDescriptionEn(e.target.value)}
										value={secondDescriptionEn}
									/>
								</div>
							</div>
						</div>
						{/* ********************************************************************* */}
						<div className="card">
							<div className="card-header"> البيان الثالث</div>
							<div className="card-body">
								<div className="form-item">
									<label htmlFor="title">العنوان</label>
									<input
										type="text"
										id="title"
										value={thirdTitle}
										onChange={e => setThirdTitle(e.target.value)}
									/>
								</div>

								<div className="form-item">
									<label htmlFor="description">الوصف</label>
									<textarea
										id="description"
										cols="30"
										rows="2"
										onChange={e => setThirdDescription(e.target.value)}
										value={thirdDescription}
									/>
								</div>

								<div className="form-item">
									<label htmlFor="title"> العنوان بالانجليزيه </label>
									<input
										type="text"
										id="title"
										onChange={e => setThirdTitleEn(e.target.value)}
										value={thirdTitleEn}
									/>
								</div>

								<div className="form-item">
									<label htmlFor="description"> الوصف بالانجليزيه</label>
									<textarea
										id="description"
										cols="30"
										rows="2"
										onChange={e => setThirdDescriptionEn(e.target.value)}
										value={thirdDescriptionEn}
									/>
								</div>
							</div>
						</div>
						{/* ********************************************************************* */}
					</div>
					<div className="col-md-12">
						<div className="card">
							<div className="card-body">
								<div className="form-item">
									<input type="file" onChange={handlePicture} id="picture" />
									<img src={picture} alt="avatar" className="imagePreview" />
								</div>
							</div>
						</div>
						<div className="form-item mt-3">
							<button className="bt bt-success btn-block">ارسال</button>
						</div>
					</div>
				</div>
			</form>
		</section>
	);
};

export default InformationShow;
