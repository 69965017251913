import React, { useEffect, useState } from "react";
import axios from "axios";
import cookie from "js-cookie";
import { Container, Col, Row } from "reactstrap";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import userPlaceholder from "../../assets/images/placeholder.jpg";
import { useDropzone } from "react-dropzone";
import Select from "react-select";
import { useHistory } from "react-router";
import Loader from "../../components/shared/Loader";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { updateMeta } from "../../store/actions/metaAction";
import { v4 } from "uuid";
const options = [
	{ value: "chocolate", label: "Chocolate" },
	{ value: "strawberry", label: "Strawberry" },
	{ value: "vanilla", label: "Vanilla" },
];

const BlogShow = ({ match }) => {
	const {
		params: { blogID },
	} = match;
	const parser = new DOMParser();
	const auth = useSelector(state => state.auth.user);
	const [titleAr, setTitleAr] = useState("");
	const [titleEn, setTitleEn] = useState("");
	const [descriptionAr, setDescriptionAr] = useState("");
	const [descriptionEN, setDescriptionEn] = useState("");
	const [contentAr, setContentAr] = useState("");
	const [contentEn, setContentEn] = useState("");
	const [files, setFiles] = useState([]);
	const [picture, setPicture] = useState({
		image: userPlaceholder,
		blob: true,
	});
	const history = useHistory();
	const [loading, setLoading] = useState(true);
	const [categories, setCategories] = useState([]);
	const [quote, setQoute] = useState({});
	const [selectedCategory, setSelectedCategory] = useState();
	const dispatch = useDispatch();
	const [imageArray, setImageArray] = useState([]);
	const [midContentObj, setMidcontentObj] = useState({
		image: { image: userPlaceholder, blob: true },
		content: {
			ar: "",
			en: "",
		},
	});
	const [DataLoaded, setDataLoaded] = useState(false);
	const [currentTag, setCurrentTag] = useState("");
	const [tags, setTags] = useState([]);

	let editor = null;

	useEffect(() => {
		onStart();
	}, []);
	const handleOnCurrentTagChange = e => {
		setCurrentTag(e.target.value);
	};
	const handleTag = e => {
		const selectedtag = { id: v4(), value: currentTag };

		if (e.key === "Enter") {
			if (tags.filter(tag => tag.value === selectedtag.value).length > 0) {
				toast.warn("يرجاء اضافة كلمة دالية جديدة");
				return;
			}
			setTags([...tags, selectedtag]);
			setCurrentTag("");
		}
	};

	const { getRootProps, getInputProps } = useDropzone({
		accept: "image/*",
		multiple: false,
		onDrop: acceptedFiles => {
			setFiles(
				acceptedFiles.map(file =>
					Object.assign(file, {
						preview: URL.createObjectURL(file),
					})
				)
			);
		},
	});

	const handleImageUpload = async e => {
		if (!files.length) {
			toast.error("برجاء إختيار صورة اولا");
			return;
		}
		e.preventDefault();
		setLoading(true);
		for (let i = 0; i < files.length; i++) {
			const formData = new FormData();
			formData.append(`file`, files[i]);
			await axios
				.post(`${process.env.REACT_APP_API_URL}/upload`, formData, {
					headers: {
						Authorization: `Bearer ${cookie.get("token")}`,
						Accept: "application/json",
						"Content-Type": "multipart/form-data",
					},
				})
				.then(function (responseImage) {
					imageArray.push(
						process.env.REACT_APP_MAIN_URL + responseImage.data.data.file.path
					);
					setImageArray(imageArray);
				})
				.catch(function (error) {
					toast.error("خطأ، برجاء المحاولة مره اخرى!");
					setLoading(false);
				});
		}
		setLoading(false);
		toast.success("تم رفع الصور بنجاح!");
	};

	const deleteImage = (event, img) => {
		event.preventDefault();
		const arr = imageArray.filter(item => {
			return item !== img;
		});
		setImageArray(arr);
	};

	const onStart = () => {
		dispatch(
			updateMeta({
				title: "تعديل تفاصيل المقال",
				breadcrumb: "المدونة",
				button: {
					show: false,
					text: null,
					link: null,
				},
			})
		);
		axios
			.get(`${process.env.REACT_APP_API_URL}/blogs/${blogID}`, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
				},
			})
			.then(function (response) {
				setTitleAr(response.data.data.blogObj.title.ar);
				setTitleEn(response.data.data.blogObj.title.en);
				setDescriptionAr(response.data.data.blogObj.description.ar);
				setDescriptionEn(response.data.data.blogObj.description.en);
				setContentEn(
					response.data?.data?.blogObj?.contents?.page_contentObj?.en ?? ""
				);
				setContentAr(
					response.data?.data?.blogObj?.contents?.page_contentObj?.ar ?? ""
				);
				setQoute(
					response.data.data.blogObj?.contents?.qouts ?? { ar: "", en: "" }
				);
				setImageArray(response.data?.data?.blogObj?.contents?.images ?? []);
				setMidcontentObj({
					...response.data?.data?.blogObj?.contents?.middle_content,
				});
				setPicture({ ...response.data.data.blogObj.image });
				setSelectedCategory(response.data.data.blogObj.category);
				setTags([
					...response.data.data.blogObj?.tags.map(tag => ({
						id: v4(),
						value: tag,
					})),
				]);
				setDataLoaded(true);
			})
			.catch(function (error) {
				console.log(error);
				toast.error("خطأ، برجاء المحاولة لاحقا!");
			});
		axios
			.get(`${process.env.REACT_APP_API_URL}/categories`, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
				},
			})
			.then(function (response) {
				setCategories(response.data.data.categories);
				setLoading(false);
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة لاحقا!");
			});
	};

	const handlePicture = e => {
		setLoading(true);
		const fileInput = document.querySelector(`#${e.target.id}`);
		e.target.id === "picture"
			? setPicture({
					image: URL.createObjectURL(fileInput.files[0]),
					blob: true,
			  })
			: setMidcontentObj({
					...midContentObj,
					image: {
						image: URL.createObjectURL(fileInput.files[0]),
						blob: true,
					},
			  });
		const data = new FormData();
		data.append("file", fileInput.files[0]);
		axios
			.post(`${process.env.REACT_APP_MAIN_URL}api/upload`, data, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
					"Content-Type": "multipart/form-data",
				},
			})
			.then(response => {
				e.target.id === "picture"
					? setPicture({
							image:
								process.env.REACT_APP_MAIN_URL + response.data.data.file.path,
							blob: false,
					  })
					: setMidcontentObj({
							...midContentObj,
							image: {
								image:
									process.env.REACT_APP_MAIN_URL + response.data.data.file.path,
								blob: false,
							},
					  });
				setLoading(false);
				toast.success("تم رفع الصورة بنجاح!");
			})
			.catch(error => {
				toast.error("خطأ في رفع الصورة، برجاء المحاولة مرة أخرى!");
				setLoading(false);
			});
	};
	const thumbs = files.map(file => (
		<div className="thumb" key={file.name}>
			<div className="thumbInner">
				<img src={file.preview} className="img" />
			</div>
		</div>
	));
	const handleSubmit = e => {
		e.preventDefault();
		setLoading(true);
		console.log({
			category: selectedCategory,
			tags: [...tags.map(tag => tag.value)],
			image: picture,
			title: { ar: titleAr, en: titleEn },
			description: {
				ar: descriptionAr,
				en: descriptionEN,
			},
			contents: {
				descriptionInBrev: {
					ar:
						parser.parseFromString(descriptionAr, "text/html").body.children[0]
							.innerText + "...",
					en:
						parser.parseFromString(descriptionEN, "text/html").body.children[0]
							.innerText + "...",
				},
				page_contentObj: {
					ar: contentAr,
					en: contentEn,
				},
				titleObj: {
					ar: titleAr,
					en: titleEn,
				},
				images: imageArray,
				qouts: quote,
				middle_content: {
					...midContentObj,
				},
			},
		});
		axios
			.put(
				`${process.env.REACT_APP_API_URL}blogs/${blogID}`,
				{
					category: selectedCategory,
					tags: [...tags.map(tag => tag.value)],
					image: picture,
					title: { ar: titleAr, en: titleEn },
					description: {
						ar: descriptionAr,
						en: descriptionEN,
					},
					contents: {
						descriptionInBrev: {
							ar:
								parser.parseFromString(descriptionAr, "text/html").body
									.children[0].innerText + "...",
							en:
								parser.parseFromString(descriptionEN, "text/html").body
									.children[0].innerText + "...",
						},
						page_contentObj: {
							ar: contentAr,
							en: contentEn,
						},
						titleObj: {
							ar: titleAr,
							en: titleEn,
						},
						images: imageArray,
						qouts: quote,
						middle_content: {
							...midContentObj,
						},
					},
				},
				{
					headers: {
						Authorization: `Bearer ${cookie.get("token")}`,
						Accept: "application/json",
					},
				}
			)
			.then(function (response) {
				toast.success("تم تحديث المقالة بنجاح!");
				history.push("/blog/index");
				setLoading(false);
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة مره اخرى!");
				setLoading(false);
				console.log(error);
			});
	};

	return (
		<section>
			{loading && <Loader />}
			<div className="row">
				<div className="col-md-9">
					<div className="card">
						<div className="card-body">
							<div className="form-item">
								<label htmlFor="category">التصنيف</label>
								<select
									id="category"
									value={selectedCategory}
									onChange={e => setSelectedCategory(e.target.value)}
								>
									{selectedCategory}
									{categories.map(category => (
										<option value={category.id} key={category.id}>
											{category.title.ar}
										</option>
									))}
								</select>
							</div>
							<div className="form-item">
								<label htmlFor="title">العنوان</label>
								<input
									type="text"
									id="title"
									required
									onChange={e => setTitleAr(e.target.value)}
									value={titleAr}
								/>
							</div>
							<div className="form-item">
								<label htmlFor="title">العنوان باللغة الإنجليزية</label>
								<input
									type="text"
									id="title"
									required
									onChange={e => setTitleEn(e.target.value)}
									value={titleEn}
								/>
							</div>

							<div className="form-item">
								<label htmlFor="title">اقتبس</label>
								<input
									type="text"
									id="title"
									required
									onChange={e =>
										setQoute(v => {
											return { ...v, ar: e.target.value };
										})
									}
									value={quote?.ar ? quote.ar : ""}
								/>
							</div>
							<div className="form-item">
								<label htmlFor="title">اقتبس باللغة الإنجليزية</label>
								<input
									type="text"
									id="title"
									required
									onChange={e =>
										setQoute(v => {
											return { ...v, en: e.target.value };
										})
									}
									value={quote?.en ? quote.en : ""}
								/>
							</div>

							<div className="form-item">
								<label htmlFor="description">الوصف</label>
								<CKEditor
									data={descriptionAr}
									onReady={editor => {
										editor.ui
											.getEditableElement()
											.parentElement.insertBefore(
												editor.ui.view.toolbar.element,
												editor.ui.getEditableElement()
											);
										editor = editor;
									}}
									onError={({ willEditorRestart }) => {
										if (willEditorRestart) {
											editor.ui.view.toolbar.element.remove();
										}
									}}
									onChange={(event, editor) => {
										const data = editor.getData();
										setDescriptionAr(data);
									}}
									editor={DecoupledEditor}
									config={{
										contentsLangDirection: "rtl",
										language: "ar",
									}}
								/>
							</div>
							<div className="form-item">
								<label htmlFor="description">الوصف باللغة الإنجليزية</label>
								<CKEditor
									data={descriptionEN}
									onReady={editor => {
										editor.ui
											.getEditableElement()
											.parentElement.insertBefore(
												editor.ui.view.toolbar.element,
												editor.ui.getEditableElement()
											);
										editor = editor;
									}}
									onError={({ willEditorRestart }) => {
										if (willEditorRestart) {
											editor.ui.view.toolbar.element.remove();
										}
									}}
									onChange={(event, editor) => {
										const data = editor.getData();
										setDescriptionEn(data);
									}}
									editor={DecoupledEditor}
									config={{
										contentsLangDirection: "ltr",
										language: "en",
									}}
								/>
							</div>

							<div className="form-item">
								<Container>
									<Row>
										<Col xs={12} md={8}>
											<div className="inputfildeContainer">
												<label htmlFor="MidContent">المحتوي الوصفي</label>
												<CKEditor
													id={"MidContent"}
													data={
														DataLoaded && midContentObj?.content?.ar
															? midContentObj?.content?.ar
															: ""
													}
													onReady={editor => {
														editor.ui
															.getEditableElement()
															.parentElement.insertBefore(
																editor.ui.view.toolbar.element,
																editor.ui.getEditableElement()
															);
														editor = editor;
													}}
													onError={({ willEditorRestart }) => {
														if (willEditorRestart) {
															editor.ui.view.toolbar.element.remove();
														}
													}}
													onChange={(event, editor) => {
														const data = editor.getData();
														setMidcontentObj({
															...midContentObj,
															content: { ...midContentObj.content, ar: data },
														});
													}}
													editor={DecoupledEditor}
													config={{
														contentsLangDirection: "rtl",
														language: "ar",
													}}
												/>
											</div>
											<div className="inputfildeContainer">
												<label htmlFor="MidContentEn">
													المحتوي الوصفي بالانجليزية
												</label>
												<CKEditor
													id={"MidContentEn"}
													data={
														DataLoaded && midContentObj?.content?.en
															? midContentObj?.content?.en
															: ""
													}
													onReady={editor => {
														editor.ui
															.getEditableElement()
															.parentElement.insertBefore(
																editor.ui.view.toolbar.element,
																editor.ui.getEditableElement()
															);
														editor = editor;
													}}
													onError={({ willEditorRestart }) => {
														if (willEditorRestart) {
															editor.ui.view.toolbar.element.remove();
														}
													}}
													onChange={(event, editor) => {
														const data = editor.getData();
														setMidcontentObj({
															...midContentObj,
															content: { ...midContentObj.content, en: data },
														});
													}}
													editor={DecoupledEditor}
													config={{
														contentsLangDirection: "ltr",
														language: "en",
													}}
												/>
											</div>
										</Col>
										<Col xs={12} md={4}>
											<label htmlFor="midContentImg">
												{" "}
												صورة المحتوي الوصفي{" "}
											</label>
											<input
												type="file"
												src={midContentObj.image}
												alt=""
												onChange={handlePicture}
												id="midContentImg"
											/>
											<img
												src={
													midContentObj?.image?.blob
														? midContentObj.image.image
														: midContentObj.image.image
												}
												alt="avatar"
												className="imagePreview"
											/>
										</Col>
									</Row>
								</Container>
							</div>

							{/* Content */}

							<div className="form-item">
								<label htmlFor="description">المحتوي</label>
								<CKEditor
									data={contentAr}
									onReady={editor => {
										editor.ui
											.getEditableElement()
											.parentElement.insertBefore(
												editor.ui.view.toolbar.element,
												editor.ui.getEditableElement()
											);
										editor = editor;
									}}
									onError={({ willEditorRestart }) => {
										if (willEditorRestart) {
											editor.ui.view.toolbar.element.remove();
										}
									}}
									onChange={(event, editor) => {
										const data = editor.getData();
										setContentAr(data);
									}}
									editor={DecoupledEditor}
									config={{
										contentsLangDirection: "rtl",
										language: "ar",
									}}
								/>
							</div>
							<div className="form-item">
								<label htmlFor="description">المحتوي باللغة الإنجليزية</label>
								<CKEditor
									data={contentEn}
									onReady={editor => {
										editor.ui
											.getEditableElement()
											.parentElement.insertBefore(
												editor.ui.view.toolbar.element,
												editor.ui.getEditableElement()
											);
										editor = editor;
									}}
									onError={({ willEditorRestart }) => {
										if (willEditorRestart) {
											editor.ui.view.toolbar.element.remove();
										}
									}}
									onChange={(event, editor) => {
										const data = editor.getData();
										setContentEn(data);
									}}
									editor={DecoupledEditor}
									config={{
										contentsLangDirection: "ltr",
										language: "en",
									}}
								/>
							</div>

							<div className="form-item">
								<div className="dropzoneContainer">
									<div {...getRootProps({ className: "dropzone" })}>
										<input {...getInputProps()} />
										<p>اضغط هنا لرفع الصورة الداخلية</p>
									</div>
									<aside className="thumbsContainer">{thumbs}</aside>
									<button
										onClick={handleImageUpload}
										type="button"
										className="bt bt-success"
									>
										رفع الصورة
									</button>
								</div>
								<div className="row">
									{imageArray.map(img => (
										<div className="col-md-2" key={img}>
											<div className="image-preview">
												<img src={img} alt={img} className="imgList" />
												<button onClick={e => deleteImage(e, img)}>
													<i className="fas fa-trash"></i>
												</button>
											</div>
										</div>
									))}
								</div>
							</div>

							<div className="form-item">
								<label htmlFor="tagesInput">Tags</label>
								<input
									type="text"
									onChange={handleOnCurrentTagChange}
									onKeyPress={handleTag}
									value={currentTag}
									id="tagesInput"
								/>
								<div className="TagsContainer">
									{tags?.map(tag => (
										<p
											key={`${tag.id}`}
											style={{
												position: "relative",
												display: "inline-block",
												margin: ".5rem",
												padding: ".5rem 1rem",
												borderRadius: "1rem",
												cursor: "default",
												color: "white",
												background: "rgb(0,198,187)",
												background:
													"linear-gradient(158deg, rgba(0,198,187,1) -500%, rgba(0,138,131,1) 42%, rgba(0,98,179,1) 58%, rgba(0,42,77,1) 95%)",
											}}
										>
											#{tag.value}
											<span
												style={{
													marginRight: "1rem",
													cursor: "pointer",
													color: "#931515",
												}}
												onClick={() =>
													setTags([
														...tags.filter(
															targetedTag => targetedTag.id !== tag.id
														),
													])
												}
											>
												<i className="fas fa-trash" />
											</span>
										</p>
									))}
								</div>
							</div>

							{/* Content END */}
						</div>
					</div>
				</div>
				<div className="col-md-3">
					<div className="card">
						<div className="card-body">
							<div className="form-item">
								<input type="file" onChange={handlePicture} id="picture" />
								<img
									src={picture?.blob ? picture.image : picture.image}
									alt="avatar"
									className="imagePreview"
								/>
							</div>
						</div>
					</div>

					<div className="form-item mt-3">
						<button className="bt bt-success btn-block" onClick={handleSubmit}>
							ارسال
						</button>
					</div>
				</div>
			</div>
		</section>
	);
};

export default BlogShow;
