import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateMeta } from "../store/actions/metaAction";
import { Link } from "react-router-dom";

const Home = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		onStart();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onStart = async () => {
		dispatch(
			updateMeta({
				title: "الرئيسية",
				breadcrumb: "الرئيسية",
				button: {
					show: false,
					text: null,
					link: null,
				},
			})
		);
	};

	return (
		<section className="homePage">
			<p>مرحبا بك في لوحة التحكم الخاصة بك</p>
			<ul className="list-inline">
				<li className="list-inline-item">
					<Link to="/moderators/create">اضافة مشرف</Link>
				</li>
				<li className="list-inline-item">
					<Link to="/blog/create">اضافة مقالة</Link>
				</li>
				<li className="list-inline-item">
					<Link to="/services/create">اضافة خدمة</Link>
				</li>
				<li className="list-inline-item">
					<Link to="/works/create">اضافة عمل</Link>
				</li>
				<li className="list-inline-item">
					<Link to="/settings">اعدادات الموقع</Link>
				</li>
			</ul>
		</section>
	);
};

export default Home;
