import React from "react";
import { useForm } from "react-hook-form";
import { v4 } from "uuid";

export function Form({ defaultValues, children, onSubmit }) {
	const { handleSubmit, register } = useForm({ defaultValues });

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			{Array.isArray(children)
				? children.map(child => {
						return child.props.name
							? React.createElement(child.type, {
									...{
										...child.props,
										register,
										key: child.props.name,
									},
							  })
							: child;
				  })
				: children}
		</form>
	);
}

export function Input({ register, name, ...rest }) {
	return <input {...register(name)} {...rest} />;
}

export function Select({ register, options, name, selectedValue, ...rest }) {
	return (
		<select {...register(name)} {...rest}>
			{options.map(value => (
				<option
					value={value.slug}
					selected={value.slug === selectedValue}
					key={v4()}
				>
					{value?.name?.ar ?? value.name}
				</option>
			))}
		</select>
	);
}
