import React, { useEffect, useState } from "react";
import Loader from "../components/shared/Loader";
import axios from "axios";
import cookie from "js-cookie";
import { toast } from "react-toastify";
import { updateMeta } from "../store/actions/metaAction";
import { useDispatch } from "react-redux";
import { Row, Col } from "reactstrap";

const Settings = () => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(true);
	const [settings, setSettings] = useState([]);
	const [dataloaded, setDatalaoded] = useState(false);

	useEffect(() => {
		onStart();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onStart = () => {
		dispatch(
			updateMeta({
				title: "اعدادات الموقع",
				breadcrumb: "الاعدادات",
				button: {
					show: false,
					text: null,
					link: null,
				},
			})
		);
		axios
			.get(`${process.env.REACT_APP_API_URL}settings/siteInfo`, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
				},
			})
			.then(function (response) {
				setSettings(response.data.data.siteInfo);
				setLoading(false);
				setDatalaoded(true);
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة لاحقا!");
			});
	};

	const handleSubmit = e => {
		e.preventDefault();
		setLoading(true);
		axios
			.put(
				`${process.env.REACT_APP_API_URL}settings/siteInfo`,
				{ ...settings },
				{
					headers: {
						Authorization: `Bearer ${cookie.get("token")}`,
						Accept: "application/json",
					},
				}
			)
			.then(function (response) {
				toast.success("تم التحديث بنجاح!");
				setLoading(false);
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة مره اخرى!");
				setLoading(false);
			});
	};

	const handleChangeFile = name => {
		setLoading(true);
		const pictureTwo = document.querySelector(`#${name}`);
		const data = new FormData();
		data.append("file", pictureTwo.files[0]);
		axios
			.post(`${process.env.REACT_APP_API_URL}/upload`, data, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
					"Content-Type": "multipart/form-data",
				},
			})
			.then(response => {
				// return;
				axios
					.put(
						`${process.env.REACT_APP_API_URL}settings/siteInfo`,
						{
							...settings,
							appLogo:
								process.env.REACT_APP_MAIN_URL + response.data.data.file.path,
						},
						{
							headers: {
								Authorization: `Bearer ${cookie.get("token")}`,
								Accept: "application/json",
							},
						}
					)
					.then(function (response) {
						toast.success("تم التحديث بنجاح!");
						window.location.reload();
						setLoading(false);
					})
					.catch(function (error) {
						toast.error("خطأ، برجاء المحاولة مره اخرى!");
						setLoading(false);
					});
			})
			.catch(error => {
				toast.error("خطأ في رفع الصورة، برجاء المحاولة مرة أخرى!");
				setLoading(false);
			});
	};

	return (
		<section>
			{loading && <Loader />}

			<form onSubmit={handleSubmit}>
				<div className="row">
					{loading === false}
					{
						<div className="col-md-12">
							<Row>
								<Col xs={12} md={8}>
									<div className="row">
										<div className="form-item m-0 col-12 col-md-6">
											<label htmlFor={"appNameAr"}>{" اسم الموقع"}</label>
											<input
												type="text"
												name={"appNameAr"}
												value={settings?.appName?.ar ?? ""}
												onChange={e =>
													dataloaded &&
													setSettings({
														...settings,
														appName: {
															...settings.appName,
															ar: e.target.value,
														},
													})
												}
												id={"appNameAr"}
											/>
										</div>
										<div className="form-item m-0 col-12 col-md-6">
											<label htmlFor={"appNameEn"}>
												{" اسم الموقع بالانجليزية"}
											</label>
											<input
												type="text"
												name={"appNameEn"}
												value={settings?.appName?.en ?? ""}
												onChange={e =>
													dataloaded &&
													setSettings({
														...settings,
														appName: {
															...settings.appName,
															en: e.target.value,
														},
													})
												}
												id={"appNameEn"}
											/>
										</div>
									</div>
									<hr />

									<div className="row">
										<div className="form-item m-0 col-12 col-md-6">
											<label htmlFor={"appDescAr"}>{"الوصف"}</label>
											<textarea
												name={"appDescAr"}
												id={"appDescAr"}
												cols="30"
												rows="3"
												onChange={e =>
													dataloaded &&
													setSettings({
														...settings,
														appDesc: {
															...settings.appDesc,
															ar: e.target.value,
														},
													})
												}
												value={settings?.appDesc?.ar ?? ""}
											/>
										</div>

										<div className="form-item m-0 col-12 col-md-6">
											<label htmlFor={"appDescEn"}>
												{" الوصف بالانجليزية"}
											</label>
											<textarea
												name={"appDescEn"}
												id={"appDescEn"}
												cols="30"
												rows="3"
												onChange={e =>
													dataloaded &&
													setSettings({
														...settings,
														appDesc: {
															...settings.appDesc,
															en: e.target.value,
														},
													})
												}
												value={settings?.appDesc?.en ?? ""}
											/>
										</div>
									</div>
									<hr />

									<div className="form-item">
										<label htmlFor={"mapframe"}>{" موقعنا علي الخريطة "}</label>
										<textarea
											name={"mapframe"}
											id={"mapframe"}
											cols="30"
											rows="3"
											onChange={e =>
												dataloaded &&
												setSettings({
													...settings,
													mapframe: e.target.value,
												})
											}
											value={settings?.mapframe}
											style={{ direction: "ltr" }}
										/>
									</div>
									<hr />
									<div className="row">
										<div className="form-item m-0 col-12 col-md-6">
											<label htmlFor={"addressAr"}>{" العنوان بالعربية"}</label>
											<input
												type="text"
												name={"address"}
												value={settings?.address?.ar ?? ""}
												onChange={e =>
													dataloaded &&
													setSettings({
														...settings,
														address: {
															...settings.address,
															ar: e.target.value,
														},
													})
												}
												id={"addressAr"}
											/>
										</div>
										<div className="form-item m-0 col-12 col-md-6">
											<label htmlFor={"addressEn"}>
												{" العنوان بالانجليزية"}
											</label>
											<input
												type="text"
												name={"address"}
												value={settings?.address?.en ?? ""}
												onChange={e =>
													dataloaded &&
													setSettings({
														...settings,
														address: {
															...settings.address,
															en: e.target.value,
														},
													})
												}
												id={"addressEn"}
											/>
										</div>
									</div>
									<hr />

									<div className="form-item ">
										<label htmlFor={"email"}>{"البريد الالكتروني"}</label>
										<input
											type="text"
											name={"email"}
											value={settings?.email ?? ""}
											onChange={e =>
												dataloaded &&
												setSettings({
													...settings,
													email: e.target.value,
												})
											}
											id={"email"}
										/>
									</div>
									<hr />
									<div className="row">
										<div className="form-item  col-12 col-md-6">
											<label htmlFor={"phone"}>{"الهاتف"}</label>
											<input
												type="text"
												name={"phone"}
												value={settings?.phone ?? ""}
												onChange={e =>
													dataloaded &&
													setSettings({
														...settings,
														phone: e.target.value,
													})
												}
												id={"phone"}
												style={{ direction: "ltr" }}
											/>
										</div>
										<div className="form-item  col-12 col-md-6">
											<label htmlFor={"phoneSecond"}>{"رقم هاتف اخر"}</label>
											<input
												type="text"
												name={"phoneSecond"}
												value={settings?.phoneSecond ?? ""}
												onChange={e =>
													dataloaded &&
													setSettings({
														...settings,
														phoneSecond: e.target.value,
													})
												}
												id={"phoneSecond"}
												style={{ direction: "ltr" }}
											/>
										</div>

										<div className="form-item col-12">
											<label htmlFor={"whatsup"}>{"رقم الواتس اب"}</label>
											<input
												type="text"
												name={"address"}
												value={settings?.whatsup ?? ""}
												onChange={e =>
													dataloaded &&
													setSettings({
														...settings,
														whatsup: e.target.value,
													})
												}
												id={"whatsup"}
											/>
										</div>
									</div>
								</Col>
								<Col xs={12} md={4}>
									<div className="form-item">
										<label htmlFor={"AppLogo"}>{"شعار الموقع"}</label>
										<input
											type="file"
											name={"AppLogo"}
											onChange={() => handleChangeFile("AppLogo")}
											id={"AppLogo"}
										/>
										<img src={settings?.appLogo} alt="" className="applogo" />
									</div>
								</Col>
							</Row>
						</div>
					}
				</div>
				<div className="row">
					<div className="col-md-12">
						<button className="bt bt-success">ارسال</button>
					</div>
				</div>
			</form>
		</section>
	);
};

export default Settings;
